@mixin root-prop($prop: null, $value: null) {
    @if ($prop and $value) {
        #{$prop}: $value;
    }
}

@mixin fontsize($base) {
    font-size: $base;
    line-height: $base * 1.35;
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-end: 0;
    margin-inline-start: 0;
}
