@import './mixins.scss';

$default-font: 'Trebuchet MS', 'Verdana', 'Arial', serif;

$fontstack-body: $default-font;
$fontstack-headings: $default-font;

$fontweight-light: 300;
$fontweight-normal: 400;
$fontweight-bold: 700;

$fontsize-xs: 0.625em;
$fontsize-small: 0.75em;
$fontsize-normal: 1em;
$fontsize-large: 1.25em;
$fontsize-xl: 1.5em;
$fontsize-xxl: 2em;

h1 {
    @include fontsize($fontsize-xxl);
}

h2 {
    @include fontsize($fontsize-xl);
}

h3 {
    @include fontsize($fontsize-large);
}

h4 {
    @include fontsize($fontsize-normal);
}

h5 {
    @include fontsize($fontsize-small);
}

h6 {
    @include fontsize($fontsize-xs);
}
